.services_container{
    background-color: white;
}

.services_intro{
    margin-block-start: 0;
    margin-block-end: 0;
    margin-top: 0;
    /* border: 1px solid black; */
    margin: 0 auto;
    max-width: 1440px;
    /* position: relative; */
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;

}

.service_title{
    margin-block-start: 0;
    margin-block-end: 0;
    font-family: 'strech';
    font-size: 80px;
    margin-top: 100px;
    /* position: absolute; */
    /* top: 40px; */
}

.services_img{
    /* position: absolute; */
    display: flex;
    justify-content: space-around;
    /* border: 1px solid black; */
}



.service_private{
    background-image: url(/src/assets/services/merco_2.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 600px;
    height: 400px;
    cursor: pointer;
    border-radius: 20px;
}

.service_private:hover{
    background-color: #d8d8d8;
    transition: .3s ease;
}


.service_medical{
    background-image: url(/src/assets/services/merco_1.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 600px;
    height: 400px;
    cursor: pointer;
    border-radius: 20px;

}

.service_medical:hover{
    background-color: #d8d8d8;
    transition: .3s ease;
}

@media(max-width:1024px){
    .services_container{
        max-width: 1024px;
    }

    .service_medical{
        width: 500px;
    }

    .service_private{
        width: 500px;
    }
}

@media(max-width:1000px){
    .services_container{
        max-width: auto;
    }

    .service_medical{
        width: 450px;
    }

    .service_private{
        width: 450px;
    }
}

@media(max-width:850px){

    .service_medical{
        width: 400px;
    }

    .service_private{
        width: 400px;
    }
}

@media(max-width:800px){

    .service_medical{
        width: 400px;
        height: 200px;
    }

    .service_private{
        width: 400px;
        height: 200px;
    }
}

@media(max-width:799px){

    .service_medical{
        width: 380px;
    }

    .service_private{
        width: 380px;
    }
}

@media(max-width:768px){
    .service_title{
        margin-top: 30px;
        margin-bottom: 20px;
    }
    .services_img{
        flex-direction: column;
        align-items: center;
    }

    .service_medical{
        width: 500px;
        height: 300px;
    }

    .service_private{
        width: 500px;
        height: 300px;
    }
}

@media(max-width:625px){

    .service_title{
        font-size: 60px;
    }
}

@media(max-width:500px){
    .service_title{
        font-size: 50px;
    }

    .service_medical{
        width: 400px;
    }

    .service_private{
        width: 400px;
    }
}


@media(max-width:425px){
    .services_container{
        max-width: 425px;
    }

    .service_title{
        font-size: 45px;
    }

    .service_medical{
        width: 400px;
        height: 200px;
    }

    .service_private{
        width: 400px;
        height: 200px;
    }
}

@media(max-width: 375px){
    .services_container{
        max-width: 375px;
    }

    .service_medical{
        width: 375px;
        height: 200px;
    }

    .service_private{
        width: 375px;
        height: 200px;
    }
}

@media(max-width:320px){
    .services_container{
        max-width: 320px;
    }
    .service_title{
        font-size: 33px;
    }

    .services_img{
        gap: 30px;
    }

    .service_medical{
        width: 300px;
        height: 100px;
    }

    .service_private{
        width: 300px;
        height: 100px;
    }


}