/* .footer_fix{
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
} */

.footer_fix{
    background-color: black;
}

p{
    margin-block-end: 0;
    margin-block-start: 0;
}

.footer{
    display: flex;
    justify-content: space-between;
    background-color: black;
    height: 180px;
    max-width: 1440px;
    margin: 0 auto;
}

.footer_detail{
    font-family: 'strech';
    font-size: 20px;
    display: flex;
    width: 500px;
    justify-content: space-between;
    margin-top: 150px;
    margin-left: 25px;
}

.detail_link{
    text-decoration: none;
    color: white;
}

.footer_info{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    text-align: start;
    font-family: 'monument extended';
    color: white;
    margin-right: 25px;
}



.info_email{
    margin-top: 10px;
}

.info_phone{
    margin-top: 10px;
}

/* @media(max-width:1024px){
    
} */

@media(max-width:768px){
    .footer{
        flex-direction: column;
        background-color: black;
        height: 100%;
        align-items: center;
        padding-bottom: 20px;
    }
    

    .footer_detail{
        margin-top: 25px;
        justify-content: center;
        width: auto;
        margin-left: 0;
    }

    .footer_info{
        text-align: center;
        margin-top: 30px;
        margin-right: 0;
    }
}

@media(max-width:720px){
    
}


@media(max-width:428px){
    .footer_detail{
        flex-direction: column;
        gap: 20px;
        width: 100%;
        margin-left: 0;
    }

    .footer_info{
        margin-right: 0;
    }
}

@media(max-width:425px){
    .footer_detail{
        flex-direction: column;
        gap: 20px;
        width: 100%;
        margin-left: 0;
    }

    .footer_info{
        margin-right: 0;
    }
}

/* @media(max-width: 375px){
    
}

@media(max-width:320px){
    
} */
