*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}


.header{
    background-color: black;
    margin-top: 0;
    display: flex;
    flex-direction: column;
}

.telInHeader{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 220px;
    margin: 0 auto;
    padding-top: 10px;
}

.icon_tel{
    color: #F8CC49;
}

.header_tel{
    color: white;
    font-family: 'monument extended';
}

.header_separator{
    color: #F8CC49;
    font-family: 'monument extended';
}


li{
    list-style: none;
}

a{
    color: white;
    text-decoration: none;
    font-family: 'monument extended';
}

.header_logo{
    background-color: #F8CC49;
    -webkit-mask-image: url(/src/assets/header/logo.svg);
    mask-image: url(/src/assets/header/logo.svg);
    /* background-image: url(../assets/header/logo.svg); */
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 100px;
    height: 100px;
    border: 1px solid black;
    cursor: pointer;
}

.navbar{
    min-height: 70px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
}

.nav-menu{
    display: flex;
    justify-content: space-between;
    align-content: center;
    gap: 60px;
}

.nav-branding{
    font-size: 2rem;
}

.nav-link{
    transition: 0.7s ease;
}

.nav-link:hover{
    color: #F8CC49;
}


.contact{
    border: 2px solid #F8CC49;
    padding: 5px 20px 5px 20px;
    border-radius: 5px;
}

.contact:hover{
    border: 2px solid #F8CC49;
    background-color: #F8CC49;
    color: black;
}

.hamburger{
    display: none;
    cursor: pointer;
}

.bar{
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    background-color: white;
}

@media(max-width:699px){
    .navbar{
        max-width: 699px;
        flex-direction: column;
        align-items: center;
        padding-bottom: 20px;
    }
}

@media(max-width:595px){
    .nav-menu{
        flex-direction: column;
        gap: 15px;
        padding-bottom: 10px;
    }
}

@media(max-width:425px){
    .navbar{
        max-width: 425px;
        flex-direction: column;
        align-items: center;
    }

    .nav-menu{
        flex-direction: column;
        gap: 15px;
        padding-bottom: 20px;
    }

    .contact{
        margin-top: 20px;
    }

}

@media(max-width: 375px){
    .navbar{
        max-width: 375px;
        flex-direction: column;
        align-items: center;
    }

    .nav-menu{
        flex-direction: column;
        gap: 15px;
        padding-bottom: 20px;
    }

    .contact{
        margin-top: 20px;
    }
}

@media(max-width:320px){
    .navbar{
        max-width: 320px;
        flex-direction: column;
        align-items: center;
    }

    .nav-menu{
        flex-direction: column;
        gap: 15px;
        padding-bottom: 20px;
    }

    .contact{
        margin-top: 20px;
    }
}